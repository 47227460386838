<template>
  <div>
    <div v-if="!loaded">
      Loading...
    </div>
    <select-account
      v-if="showSelectAccount"
      :accounts="accessCodeUsers"
      @submit="onSetUser"
      @cancel="redirectToLogin"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SelectAccount from './SelectAccount'

const ACCOUNT_SETUP = 'AccountSetup'
const RESET_PASSWORD = 'ResetPassword'
const QUERY_FORGOT_PASSWORD = 'forgot_password'
const QUERY_RESET_PASSWORD = 'reset_password'

export default {
  name: 'ValidateToken',
  components: {
    SelectAccount
  },
  data () {
    return {
      accounts: null,
      loaded: false
    }
  },
  computed: {
    ...mapGetters({
      accessCodeUsers: 'getAccessCodeUsers',
      accessCodeUUID: 'getAccessCodeUUID',
      showSelectAccount: 'getShowSelectAccount'
    })
  },
  created () {
    if (!this.hasRequiredParams()) {
      this.redirectToLogin()
      return
    }

    this.validateToken()
  },
  methods: {
    hasRequiredParams () {
      return this.$route.query.token && this.$route.query.channel_code && this.$route.query.next
    },
    onSetUser (account) {
      this.$store.commit('security/SET_ACCESS_CODE_UUID', account.uuid)
      this.redirectToNextStep()
    },
    redirectToLogin () {
      this.$router.push({ name: 'Login' })
    },
    redirectToNextStep () {
      const name = this.$route.query.next === QUERY_FORGOT_PASSWORD || this.$route.query.next === QUERY_RESET_PASSWORD ? RESET_PASSWORD : ACCOUNT_SETUP
      const query = name === ACCOUNT_SETUP ? { step: 3, channel: this.$route.query.channel_code } : {}

      this.$router.push({ name, query })
    },
    redirectToTryAgain () {
      const name = this.$route.query.next === QUERY_FORGOT_PASSWORD || this.$route.query.next === QUERY_RESET_PASSWORD ? 'ForgotPassword' : ACCOUNT_SETUP
      this.$router.push({ name })
    },
    validateToken () {
      const data = {
        token: this.$route.query.token,
        channel: this.$route.query.channel_code,
        request_type: this.$route.query.next
      }

      this.$store.dispatch('VALIDATE_TOKEN', data)
        .then((response) => {
          if (this.accessCodeUUID) {
            this.redirectToNextStep()
          } else {
            this.loaded = true
          }
        })
        .catch(() => {
          this.$toast.error({ message: this.$t('expiredTokenLink') })
          this.redirectToTryAgain()
        })
    }
  }
}
</script>
